import { ReactComponent as Marker } from '../../images/svgs/marker.svg';
import { ReactComponent as Mobile } from '../../images/svgs/mobile.svg';
import { ReactComponent as Mail } from '../../images/svgs/mail.svg';
import { ReactComponent as Fax } from '../../images/svgs/fax.svg';
import styled from '@emotion/styled';
import { information } from 'information';

export const LocationLinkStyled = styled.div`
    a {
        text-decoration: underline;
        line-height: 24px;
        display: flex;
        color: ${({ theme }) => theme.colors.gray5};
        align-items: center;
        svg {
            transform: scale(1);
            min-width: 18px;
            fill: ${({ theme }) => theme.colors.gray5};
        }
        span {
            margin-left: 12px;
        }
    }
    @media ${({ theme }) => theme.device.laptop} {
        a {
            transition: color 0.2s ease-in;
            &:hover {
                color: ${({ theme }) => theme.colors.blue4};
                svg {
                    transition: all 0.2s ease-in-out;
                    fill: ${({ theme }) => theme.colors.blue4};
                    transform: scale(1.2);
                }
            }
        }
    }
`;

export const LocationLink: React.FC<{
    type: 'map' | 'phone' | 'mail' | 'fax';
    location: 'santa clarita' | 'porter ranch' | 'new hall';
}> = ({ type, location }) => {
    return (
        <LocationLinkStyled>
            {/* maps */}
            {type === 'map' && location.toLowerCase() === 'santa clarita' && (
                <a
                    target="__blank"
                    rel="noopener noreferrer"
                    href={information.santaClarita.locationUrl}
                >
                    <Marker />
                    <span>{information.santaClarita.location}</span>
                </a>
            )}
            {type === 'map' && location.toLowerCase() === 'porter ranch' && (
                <a
                    target="__blank"
                    rel="noopener noreferrer"
                    href={information.porterRanch.locationUrl}
                >
                    <Marker />
                    <span>{information.porterRanch.location}</span>
                </a>
            )}

            {type === 'map' && location.toLowerCase() === 'new hall' && (
                <a
                    target="__blank"
                    rel="noopener noreferrer"
                    href={information.newHall.locationUrl}
                >
                    <Marker />
                    <span>{information.newHall.location}</span>
                </a>
            )}
            {/* phone numbers */}
            {type === 'phone' && location.toLowerCase() === 'santa clarita' && (
                <a href={`tel:${information.santaClarita.phone}`}>
                    <Mobile />
                    <span>{information.santaClarita.phone}</span>
                </a>
            )}
            {type === 'phone' && location.toLowerCase() === 'porter ranch' && (
                <a href={`tel:${information.porterRanch.phone}`}>
                    <Mobile />
                    <span>{information.porterRanch.phone}</span>
                </a>
            )}
            {type === 'mail' && location.toLowerCase() === 'new hall' && (
                <a href={`mailto:${information.newHall.mail}`}>
                    <Mail />
                    <span>{information.newHall.mail}</span>
                </a>
            )}
            {type === 'phone' && location.toLowerCase() === 'new hall' && (
                <a href={`tel:${information.newHall.phone}`}>
                    <Mobile />
                    <span>{information.newHall.phone}</span>
                </a>
            )}
            {type === 'fax' && location.toLowerCase() === 'new hall' && (
                <a href={`tel:${information.newHall.fax}`}>
                    <Fax />
                    <span>{information.newHall.fax}</span>
                </a>
            )}
        </LocationLinkStyled>
    );
};
