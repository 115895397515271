import styled from '@emotion/styled';
import { ReactComponent as Google } from '../../images/svgs/google.svg';
import { ReactComponent as Facebook } from '../../images/svgs/facebook.svg';
import { ReactComponent as Instagram } from '../../images/svgs/instagram.svg';
import { ReactComponent as Yelp } from '../../images/svgs/yelp.svg';
import { ReactComponent as Logo } from '../../images/svgs/footer-logo.svg';
import { Link } from 'gatsby';

const FooterStyled = styled.footer`
    background: ${({ theme }) => theme.colors.blue1};
    padding: 40px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
        color: ${({ theme }) => theme.colors.gray5};
    }
    > svg {
        margin-bottom: 32px;
        width: 144px;
    }
    .header {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 32px;
    }
    .bottom {
        color: ${({ theme }) => theme.colors.blue1};
        background: ${({ theme }) => theme.colors.blue4};
        padding: 20px 0;
        display: flex;
        width: 100%;
        justify-content: center;
    }
    @media ${({ theme }) => theme.device.laptop} {
        padding: 64px 0px 0px;
        > svg {
            width: 235px;
            margin-bottom: 48px;
        }
        .header {
            font-size: 28px;
            line-height: 40px;
        }
    }
`;

const Social = styled.div`
    display: flex;
    width: 368px;
    margin-bottom: 32px;
    justify-content: space-between;
    p {
        color: #5c6569;
    }
    svg {
        fill: ${({ theme }) => theme.colors.blue3};
        transition: fill 0.3s ease-in-out;
        transform: scale(1);
        cursor: pointer;
        &:hover {
            transform: scale(1.1);
            fill: ${({ theme }) => theme.colors.blue4};
        }
    }
    @media ${({ theme }) => theme.device.tablet} {
        width: 518px;
        margin-bottom: 48px;
    }
    @media ${({ theme }) => theme.device.laptop} {
        margin-bottom: 56px;
        width: 354px;
    }
`;

const Links = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 48px;
    ul {
        margin-bottom: 16px;
    }
    li {
        margin-bottom: 24px;
        list-style: none;
    }
    @media ${({ theme }) => theme.device.tablet} {
        gap: 188px;
        ul {
            margin-bottom: 32px;
        }
    }
    @media ${({ theme }) => theme.device.laptop} {
        gap: 190px;
        ul {
            margin-bottom: 32px;
        }
    }
`;

export const Footer: React.FC = () => {
    return (
        <FooterStyled>
            <Logo />
            <p className="header">Navigations</p>
            <Links>
                <ul>
                    <li>
                        {' '}
                        <Link to="/for-parents/meet-the-doctors/">Meet Our Doctors </Link>
                    </li>

                    <li>
                        <Link to="/patient-resources/">Patient Resources</Link>
                    </li>
                    <li>
                        {' '}
                        <Link to="/for-parents/patient-reviews/">Testimonials</Link>
                    </li>
                    <li>
                        <Link to="/office-news/">Office News</Link>
                    </li>
                </ul>
                <ul>
                    <li>
                        <Link to="/procedures/cleanings-prevention/">Cleanings & Prevention</Link>
                    </li>
                    <li>
                        <Link to="/procedures/periodontal-disease/">Periodontal Disease</Link>
                    </li>
                    <li>
                        <Link to="/procedures/restorations/">Dental Restoration</Link>
                    </li>
                    <li>
                        <Link to="/procedures/orthodontics/">Orthodontics</Link>
                    </li>
                </ul>
            </Links>
            <Social>
                <p>Follow Us:</p>
                <a
                    aria-label="Find us on google"
                    href="https://www.google.com/maps/place/Kidz+Dental+Care/@34.4029842,-118.4589789,15z/data=!4m8!3m7!1s0x80c289a4da7c57a1:0x4c311ee1864ab811!8m2!3d34.4029842!4d-118.4589789!9m1!1b1!16s%2Fg%2F11g07b2_9l"
                    rel="noopener noorefferer"
                    target="__blank"
                >
                    <Google />
                </a>
                <a
                    aria-label="Follow us on Facebook"
                    href="https://www.facebook.com/kdcscv/"
                    rel="noopener noorefferer"
                    target="__blank"
                >
                    <Facebook />
                </a>
                <a
                    aria-label="Follow us on Instagram"
                    href="https://www.instagram.com/kidzdentalcare661/?hl=en"
                    rel="noopener noorefferer"
                    target="__blank"
                >
                    <Instagram />
                </a>
                <a
                    aria-label="leave a review on yelp"
                    href="https://www.yelp.com/biz/kidz-dental-care-porter-ranch"
                    rel="noopener noorefferer"
                    target="__blank"
                >
                    <Yelp />
                </a>
            </Social>
            <p className="bottom">© {new Date().getFullYear()} KIDZ DENTAL CARE</p>
        </FooterStyled>
    );
};
