// import { theme } from '@/GlobalStyles';
import styled from '@emotion/styled';
import { ReactComponent as ArrowShort } from '../../images/svgs/arrow-button-short.svg';
import { ElementType } from 'react';

interface ButtonProps {
    fill?: boolean;
    color: string;
    children?: React.ReactNode;
    as?: ElementType;
    to?: string;
}

export const ButtonPrimary = styled.button<ButtonProps>`
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background: ${({ theme, color, fill }) => {
        if (fill && color === 'blue') {
            return theme.colors.blue3;
        } else if (!fill && color === 'blue') {
            return 'none';
        } else {
            return 'rgba(255, 255, 255, 0.4)';
        }
    }};
    border: ${({ theme, color, fill }) => {
        if (fill && color === 'blue') {
            return 'none';
        } else if (!fill && color === 'blue') {
            return `${theme.colors.blue3} solid 2px`;
        } else {
            return `${theme.colors.gray1} solid 2px`;
        }
    }};
    color: ${({ theme, color, fill }) => {
        if (fill && color === 'blue') {
            return `${theme.colors.gray1}`;
        } else if (!fill && color === 'blue') {
            return `${theme.colors.blue3}`;
        } else {
            return `${theme.colors.gray1}`;
        }
    }};
    padding: 16px 68px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    svg {
        fill: ${({ theme, color, fill }) => {
            if (fill && color === 'blue') {
                return `${theme.colors.gray1}`;
            } else if (!fill && color === 'blue') {
                return `${theme.colors.blue3}`;
            }
        }};
        transition: all 0.2s ease-in-out;
        width: 18px;
    }
    :hover {
        background: ${({ theme, color, fill }) => {
            if (fill && color === 'blue') {
                return `${theme.colors.blue4}`;
            } else if (!fill && color === 'blue') {
                return `rgba(122, 223, 255, 0.4)`;
            } else {
                return `rgba(185, 230, 244, 0.4)`;
            }
        }};
        color: ${({ theme, color, fill }) => {
            if (!fill && color === 'blue') {
                return `${theme.colors.blue4}`;
            }
        }};
        border: ${({ theme, color, fill }) => {
            if (!fill && color === 'blue') {
                return `${theme.colors.blue4} solid 2px`;
            }
        }};
        svg {
            transform: rotate(10deg);
        }
    }
    @media (min-width: 1020px) {
        padding: 16px 28px;
    }
`;

export const StyledButtonSecondary = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
    color: ${({ theme, color, fill }) => {
        if (fill && color === 'blue') {
            return `${theme.colors.gray1}`;
        } else if (!fill && color === 'blue') {
            return `${theme.colors.blue3}`;
        }
    }};
    span {
        margin-right: 16px;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        border: ${({ theme, color, fill }) => {
            if (fill && color === 'blue') {
                return `${theme.colors.gray1}`;
            } else if (!fill && color === 'blue') {
                return `2px solid ${theme.colors.blue3}`;
            }
        }};
    }
    svg {
        width: 14px;
        height: 15px;
        transition: all 0.2s ease-in-out;
        fill: ${({ theme, color, fill }) => {
            if (fill && color === 'blue') {
                return `${theme.colors.gray1}`;
            } else if (!fill && color === 'blue') {
                return `${theme.colors.blue3}`;
            }
        }};
    }
    @media (min-width: 630px) {
        :hover {
            div {
                svg {
                    transform: rotate(45deg);
                }
            }
        }
    }
`;

export const ButtonSecondary = (props: ButtonProps) => {
    return (
        <StyledButtonSecondary {...props}>
            <span>{props.children}</span>
            <div>
                <ArrowShort />
            </div>
        </StyledButtonSecondary>
    );
};

const StyledTitle = styled.h2<TitleProps>`
    text-decoration: none;
    position: relative;
    text-align: ${({ side }) => (side === 'center' ? 'center' : 'left')};
    margin-bottom: 48px;
    &:after {
        position: absolute;
        content: '';
        height: 3px;
        bottom: -16px;
        margin: 0 auto;
        margin: ${({ side }) => (side === 'center' ? '0 auto' : '0')};
        left: 0;
        right: 0;
        width: 65%;
        background: ${({ theme }) => theme.colors.blue3};
        -o-transition: 0.5s;
        -ms-transition: 0.5s;
        -moz-transition: 0.5s;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
    @media (min-width: 1030px) {
        margin-bottom: 48px;
        &:after {
        }
    }
`;

interface TitleProps {
    side: string;
    children: React.ReactNode;
    className: string;
}

export const Title = (props: TitleProps) => {
    return (
        <StyledTitle {...props}>
            <span>{props.children}</span>
        </StyledTitle>
    );
};
