exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-4-mythbusters-about-teething-that-new-parents-must-know-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/4-mythbusters-about-teething-that-new-parents-must-know/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-4-mythbusters-about-teething-that-new-parents-must-know-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-4-tips-to-protect-your-tooth-enamel-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/4-tips-to-protect-your-tooth-enamel/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-4-tips-to-protect-your-tooth-enamel-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-5-easy-steps-to-a-good-dental-hygiene-routine-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/5-easy-steps-to-a-good-dental-hygiene-routine/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-5-easy-steps-to-a-good-dental-hygiene-routine-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-5-reasons-to-get-a-full-smile-makeover-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/5-reasons-to-get-a-full-smile-makeover/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-5-reasons-to-get-a-full-smile-makeover-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-5-tips-healthier-gums-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/5-tips-healthier-gums/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-5-tips-healthier-gums-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-8-faqs-about-orthodontic-treatments-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/8-faqs-about-orthodontic-treatments/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-8-faqs-about-orthodontic-treatments-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-after-orthodontic-treatment-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/after-orthodontic-treatment/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-after-orthodontic-treatment-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-all-about-dental-crowns-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/all-about-dental-crowns/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-all-about-dental-crowns-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-all-about-water-flossing-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/all-about-water-flossing/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-all-about-water-flossing-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-all-you-need-to-know-about-root-canals-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/all-you-need-to-know-about-root-canals/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-all-you-need-to-know-about-root-canals-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-an-overview-of-dental-care-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/an-overview-of-dental-care/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-an-overview-of-dental-care-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-avoid-dry-socket-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/avoid-dry-socket/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-avoid-dry-socket-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-best-way-to-whiten-my-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/best-way-to-whiten-my-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-best-way-to-whiten-my-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-bruxism-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/bruxism/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-bruxism-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-cerec-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/cerec/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-cerec-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-chip-tooth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/chip-tooth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-chip-tooth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-choosing-the-best-toothpaste-floss-and-mouthwash-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/choosing-the-best-toothpaste-floss-and-mouthwash/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-choosing-the-best-toothpaste-floss-and-mouthwash-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-causes-treatments-gingivitis-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/common-causes-treatments-gingivitis/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-causes-treatments-gingivitis-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-dental-restorations-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/common-dental-restorations/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-dental-restorations-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-questions-dental-braces-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/common-questions-dental-braces/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-questions-dental-braces-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-questions-wisdom-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/common-questions-wisdom-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-questions-wisdom-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-veneer-questions-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/common-veneer-questions/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-common-veneer-questions-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-composite-bondings-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/composite-bondings/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-composite-bondings-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-comprehensive-guide-to-cosmetic-dentistry-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/comprehensive-guide-to-cosmetic-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-comprehensive-guide-to-cosmetic-dentistry-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-cosmetic-dentistry-trends-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/cosmetic-dentistry-trends/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-cosmetic-dentistry-trends-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-cosmetic-solutions-for-uneven-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/cosmetic-solutions-for-uneven-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-cosmetic-solutions-for-uneven-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-debunking-myths-about-adult-braces-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/debunking-myths-about-adult-braces/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-debunking-myths-about-adult-braces-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-dental-appointment-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/dental-appointment/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-dental-appointment-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-dental-implants-improve-health-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/dental-implants-improve-health/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-dental-implants-improve-health-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-dental-implants-or-dentures-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/dental-implants-or-dentures/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-dental-implants-or-dentures-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-dentist-vs-oral-surgeon-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/dentist-vs-oral-surgeon/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-dentist-vs-oral-surgeon-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-detecting-oral-cancer-before-its-too-late-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/detecting-oral-cancer-before-its-too-late/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-detecting-oral-cancer-before-its-too-late-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-difference-between-overjet-and-overbite-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/difference-between-overjet-and-overbite/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-difference-between-overjet-and-overbite-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-does-everyonoe-have-wisdom-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/does-everyonoe-have-wisdom-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-does-everyonoe-have-wisdom-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-electric-vs-manual-toothbrushes-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/electric-vs-manual-toothbrushes/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-electric-vs-manual-toothbrushes-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-essential-vitamins-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/essential-vitamins/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-essential-vitamins-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-everything-you-need-to-know-about-cerec-crowns-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/everything-you-need-to-know-about-cerec-crowns/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-everything-you-need-to-know-about-cerec-crowns-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-fluorisis-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/fluorisis/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-fluorisis-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-foods-to-eat-if-you-have-gum-disease-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/foods-to-eat-if-you-have-gum-disease/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-foods-to-eat-if-you-have-gum-disease-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-frequently-asked-questions-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/frequently-asked-questions/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-frequently-asked-questions-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-full-mouth-reconstruction-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/full-mouth-reconstruction/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-full-mouth-reconstruction-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-fun-ways-personal-braces-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/fun-ways-personal-braces/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-fun-ways-personal-braces-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-gingivitis-periodontitis-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/gingivitis-periodontitis/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-gingivitis-periodontitis-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-grand-opening-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/grand-opening/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-grand-opening-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-a-bone-graft-can-help-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-a-bone-graft-can-help/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-a-bone-graft-can-help-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-canyou-imprice-your-smile-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-canyou-imprice-your-smile/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-canyou-imprice-your-smile-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-dental-care-helps-sleep-apnea-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-dental-care-helps-sleep-apnea/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-dental-care-helps-sleep-apnea-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-do-i-fknow-if-my-gums-are-healthy-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-do-i-fknow-if-my-gums-are-healthy/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-do-i-fknow-if-my-gums-are-healthy-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-does-laughing-gas-help-in-my-kids-dental-treatment-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-does-laughing-gas-help-in-my-kids-dental-treatment/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-does-laughing-gas-help-in-my-kids-dental-treatment-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-goog-oral-health-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-goog-oral-health/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-goog-oral-health-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-important-is-your-choice-of-denta-crown-material-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-important-is-your-choice-of-denta-crown-material/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-important-is-your-choice-of-denta-crown-material-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-lasers-are-transforming-restorative-dentistry-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-lasers-are-transforming-restorative-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-lasers-are-transforming-restorative-dentistry-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-teeth-whitening-works-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-teeth-whitening-works/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-teeth-whitening-works-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-clean-your-teeth-with-braces-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-clean-your-teeth-with-braces/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-clean-your-teeth-with-braces-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-eat-after-oral-surgery-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-eat-after-oral-surgery/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-eat-after-oral-surgery-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-handle-dental-emergencies-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-handle-dental-emergencies/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-handle-dental-emergencies-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-maintain-results-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-maintain-results/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-maintain-results-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-make-flossing-fun-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-make-flossing-fun/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-make-flossing-fun-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-prepare-for-oral-surgery-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-prepare-for-oral-surgery/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-prepare-for-oral-surgery-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-prevent-toddler-tooth-devay-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-prevent-toddler-tooth-devay/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-prevent-toddler-tooth-devay-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-reduce-sugar-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/how-to-reduce-sugar/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-how-to-reduce-sugar-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-implant-supported-dentures-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/implant-supported-dentures/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-implant-supported-dentures-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-importance-of-pediatric-dental-care-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/importance-of-pediatric-dental-care/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-importance-of-pediatric-dental-care-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-link-between-gum-disease-and-heart-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/link-between-gum-disease-and-heart/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-link-between-gum-disease-and-heart-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-loook-years-younger-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/loook-years-younger/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-loook-years-younger-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-motor-vehicles-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/motor-vehicles/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-motor-vehicles-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-mouth-guard-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/mouth-guard/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-mouth-guard-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-oeal-health-during-pregnancy-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/oeal-health-during-pregnancy/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-oeal-health-during-pregnancy-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-optimal-gum-health-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/optimal-gum-health/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-optimal-gum-health-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-oral-health-care-for-each-stage-of-life-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/oral-health-care-for-each-stage-of-life/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-oral-health-care-for-each-stage-of-life-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-oral-health-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/oral-health/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-oral-health-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-pediatric-dentistry-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/pediatric-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-pediatric-dentistry-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-plaque-vs-tartar-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/plaque-vs-tartar/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-plaque-vs-tartar-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-preparing-to-reopen-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/preparing-to-reopen/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-preparing-to-reopen-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-preventing-cold-canker-sores-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/preventing-cold-canker-sores/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-preventing-cold-canker-sores-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-protect-kids-halloween-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/protect-kids-halloween/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-protect-kids-halloween-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-purpose-of-periodontak-charting-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/purpose-of-periodontak-charting/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-purpose-of-periodontak-charting-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-questions-to-ask-dental-checkups-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/questions-to-ask-dental-checkups/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-questions-to-ask-dental-checkups-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-questions-to-ask-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/questions-to-ask/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-questions-to-ask-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-questions-to-ask-when-looking-for-an-orthodontics-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/questions-to-ask-when-looking-for-an-orthodontics/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-questions-to-ask-when-looking-for-an-orthodontics-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-reasons-for-root-canal-therapy-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/reasons-for-root-canal-therapy/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-reasons-for-root-canal-therapy-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-reasons-why-your-bite-can-shift-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/reasons-why-your-bite-can-shift/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-reasons-why-your-bite-can-shift-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-recognizing-symptoms-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/recognizing-symptoms/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-recognizing-symptoms-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-recovering-from-oral-surgery-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/recovering-from-oral-surgery/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-recovering-from-oral-surgery-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-root-canal-therapy-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/root-canal-therapy/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-root-canal-therapy-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-seal-the-deal-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/seal-the-deal/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-seal-the-deal-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-should-you-see-a-periodontist-before-getting-braces-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/should-you-see-a-periodontist-before-getting-braces/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-should-you-see-a-periodontist-before-getting-braces-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-sinus-lift-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/sinus-lift/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-sinus-lift-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-sleep-apnea-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/sleep-apnea/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-sleep-apnea-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-slow-down-the-aging-process-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/slow-down-the-aging-process/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-slow-down-the-aging-process-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-smoking-oral-health-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/smoking-oral-health/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-smoking-oral-health-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-the-difference-between-traditional-and-no-prep-veneers-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/the-difference-between-traditional-and-no-prep-veneers/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-the-difference-between-traditional-and-no-prep-veneers-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-thumb-sucking-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/thumb-sucking/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-thumb-sucking-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tiny-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tiny-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tiny-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-braces-more-comfortable-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tips-braces-more-comfortable/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-braces-more-comfortable-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-creating-effective-hygeine-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tips-for-creating-effective-hygeine/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-creating-effective-hygeine-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-keeping-brush-clean-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tips-for-keeping-brush-clean/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-keeping-brush-clean-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-keeping-your-dentures-clean-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tips-for-keeping-your-dentures-clean/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-keeping-your-dentures-clean-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-stressful-tooth-extraction-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tips-for-stressful-tooth-extraction/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-stressful-tooth-extraction-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-taking-care-of-dentures-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tips-for-taking-care-of-dentures/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tips-for-taking-care-of-dentures-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tmd-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tmd/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tmd-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-tmj-tmd-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/tmj-tmd/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-tmj-tmd-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-top-3-causes-oof-child-tooth-decay-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/top-3-causes-oof-child-tooth-decay/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-top-3-causes-oof-child-tooth-decay-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-top-6-questions-to-ask-your-oral-surgeon-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/top-6-questions-to-ask-your-oral-surgeon/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-top-6-questions-to-ask-your-oral-surgeon-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-untangling-tongue-tie-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/untangling-tongue-tie/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-untangling-tongue-tie-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-versatility-of-lasers-in-cosmetic-dentistry-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/versatility-of-lasers-in-cosmetic-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-versatility-of-lasers-in-cosmetic-dentistry-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-can-i-eat-after-tooth-extracted-inedx-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-can-i-eat-after-tooth-extracted/inedx.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-can-i-eat-after-tooth-extracted-inedx-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-can-intraoral-cameras-do-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-can-intraoral-cameras-do/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-can-intraoral-cameras-do-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-canyou-expect-with-dental-implant-treatment-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-canyou-expect-with-dental-implant-treatment/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-canyou-expect-with-dental-implant-treatment-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-causes-a-gummy-smile-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-causes-a-gummy-smile/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-causes-a-gummy-smile-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-causes-sensitive-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-causes-sensitive-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-causes-sensitive-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-causes-yellow-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-causes-yellow-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-causes-yellow-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-considered-oral-surgery-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-is-considered-oral-surgery/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-considered-oral-surgery-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-leukoplakia-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-is-leukoplakia/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-leukoplakia-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-minimally-invasive-dentistry-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-is-minimally-invasive-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-minimally-invasive-dentistry-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-occlusal-disease-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-is-occlusal-disease/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-occlusal-disease-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-preventative-care-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-is-preventative-care/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-preventative-care-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-the-difference-bettwen-filling-and-inlays-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-is-the-difference-bettwen-filling-and-inlays/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-is-the-difference-bettwen-filling-and-inlays-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-to-do-about-sensitive-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-to-do-about-sensitive-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-to-do-about-sensitive-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-to-expect-dental-implants-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-to-expect-dental-implants/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-to-expect-dental-implants-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-to-expect-when-having-a-tooth-extracted-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-to-expect-when-having-a-tooth-extracted/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-to-expect-when-having-a-tooth-extracted-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-treatments-are-included-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-treatments-are-included/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-treatments-are-included-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-you-need-to-know-about-dry-moouth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-you-need-to-know-about-dry-moouth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-you-need-to-know-about-dry-moouth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-you-should-know-about-getting-braces-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/what-you-should-know-about-getting-braces/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-what-you-should-know-about-getting-braces-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-are-straight-teeth-important-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-are-straight-teeth-important/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-are-straight-teeth-important-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-flossing-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-flossing/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-flossing-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-go-to-dentist-for-clear-aligners-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-go-to-dentist-for-clear-aligners/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-go-to-dentist-for-clear-aligners-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-is-periodontal-charing-important-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-is-periodontal-charing-important/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-is-periodontal-charing-important-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-should-cavities-in-baby-teeth-need-to-be-treated-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-should-cavities-in-baby-teeth-need-to-be-treated/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-should-cavities-in-baby-teeth-need-to-be-treated-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-you-shouldnt-ignore-missing-teeth-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-you-shouldnt-ignore-missing-teeth/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-you-shouldnt-ignore-missing-teeth-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-you-shouldnt-wait-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/why-you-shouldnt-wait/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-why-you-shouldnt-wait-index-mdx" */),
  "component---src-templates-alt-template-js-content-file-path-src-content-blog-wisdom-teeth-removal-index-mdx": () => import("./../../../src/templates/alt-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/blog/wisdom-teeth-removal/index.mdx" /* webpackChunkName: "component---src-templates-alt-template-js-content-file-path-src-content-blog-wisdom-teeth-removal-index-mdx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/blog-list-template.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-appointments-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/appointments/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-appointments-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-for-parents-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/for-parents/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-for-parents-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-meet-our-staff-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/meet-our-staff/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-meet-our-staff-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-meet-the-doctors-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/meet-the-doctors/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-meet-the-doctors-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-office-tour-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/office-tour/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-office-tour-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-our-community-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/our-community/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-our-community-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-patient-reviews-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/for-parents/patient-reviews/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-for-parents-patient-reviews-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-dental-videos-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/dental-videos/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-dental-videos-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-financial-policy-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/financial-policy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-financial-policy-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-hipaa-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/hipaa/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-hipaa-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-patient-information-form-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/patient-information-form/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-patient-information-form-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-patient-resources-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/patient-resources/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-patient-resources-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-payment-options-and-arrangements-form-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/payment-options-and-arrangements-form/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-payment-options-and-arrangements-form-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-porter-ranch-forms-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/porter-ranch-forms/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-porter-ranch-forms-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-procedure-financing-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/patient-resources/procedure-financing/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-patient-resources-procedure-financing-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-6-month-smile-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/6-month-smile/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-6-month-smile-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-after-tooth-extractions-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/after-tooth-extractions/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-after-tooth-extractions-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-at-home-care-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/at-home-care/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-at-home-care-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-baby-bottle-tooth-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/baby-bottle-tooth/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-baby-bottle-tooth-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-before-general-anesthesia-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/before-general-anesthesia/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-before-general-anesthesia-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-braces-for-adults-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/braces-for-adults/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-braces-for-adults-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-braces-for-children-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/braces-for-children/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-braces-for-children-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-brushing-and-flossing-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/brushing-and-flossing/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-brushing-and-flossing-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-care-during-orthoodontic-treatment-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/care-during-orthoodontic-treatment/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-care-during-orthoodontic-treatment-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-care-following-orthodontics-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/care-following-orthodontics/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-care-following-orthodontics-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-caring-for-mouth-guards-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/caring-for-mouth-guards/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-caring-for-mouth-guards-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-causes-of-periodontal-disease-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/causes-of-periodontal-disease/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-causes-of-periodontal-disease-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-cephalometric-x-rays-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/cephalometric-x-rays/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-cephalometric-x-rays-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-cleanings-preventions-two-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/cleanings-preventions-two/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-cleanings-preventions-two-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-composite-fillings-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/composite-fillings/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-composite-fillings-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-crowns-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/crowns/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-crowns-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-damon-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/damon-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-damon-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-anxiety-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/dental-anxiety/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-anxiety-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-emergencies-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/dental-emergencies/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-emergencies-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-exams-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/dental-exams/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-exams-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-radiographs-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/dental-radiographs/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-radiographs-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-x-rays-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/dental-x-rays/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-dental-x-rays-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-diagnosis-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/diagnosis/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-diagnosis-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-digital-radiographs-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/digital-radiographs/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-digital-radiographs-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-do-braces-hurt-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/do-braces-hurt/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-do-braces-hurt-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-does-my-child-need-early-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/does-my-child-need-early/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-does-my-child-need-early-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-does-your-child-grind-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/does-your-child-grind/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-does-your-child-grind-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-early-orthodontic-treatment-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/early-orthodontic-treatment/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-early-orthodontic-treatment-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-eating-while-weearing-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/eating-while-weearing-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-eating-while-weearing-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-eruption-of-childs-teeth-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/eruption-of-childs-teeth/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-eruption-of-childs-teeth-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-first-visit-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/first-visit/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-first-visit-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-flouride-treatment-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/flouride-treatment/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-flouride-treatment-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-fluoride-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/fluoride/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-fluoride-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-good-diet-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/good-diet/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-good-diet-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-home-care-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/home-care/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-home-care-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-how-often-should-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/how-often-should/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-how-often-should-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-how-to-prevent-cavities-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/how-to-prevent-cavities/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-how-to-prevent-cavities-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-how-to-properly-brush-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/how-to-properly-brush/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-how-to-properly-brush-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-inovation-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/inovation/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-inovation-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-intraoral-cams-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/intraoral-cams/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-intraoral-cams-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-introduction-to-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/introduction-to-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-introduction-to-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-invisalign-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/invisalign/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-invisalign-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-is-it-ever-too-late-to-get-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/is-it-ever-too-late-to-get-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-is-it-ever-too-late-to-get-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-laser-teeth-whitening-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/laser-teeth-whitening/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-laser-teeth-whitening-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-lingual-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/lingual-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-lingual-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-maintanence-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/maintanence/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-maintanence-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-mouth-body-connection-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/mouth-body-connection/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-mouth-body-connection-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-mouth-guards-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/mouth-guards/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-mouth-guards-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-myobrace-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/myobrace/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-myobrace-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-oral-hygeine-aids-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/oral-hygeine-aids/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-oral-hygeine-aids-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-oral-maxillofracial-surgery-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/oral-maxillofracial-surgery/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-oral-maxillofracial-surgery-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-oral-pathology-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/oral-pathology/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-oral-pathology-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-appliances-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-appliances/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-appliances-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-conditions-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-conditions/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-conditions-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-dictionary-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-dictionary/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-dictionary-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-emergencies-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-emergencies/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-emergencies-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-exams-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-exams/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-exams-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-treatment-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-treatment-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-treatment-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-treatment-phases-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontic-treatment-phases/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontic-treatment-phases-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontics-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/orthodontics/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-orthodontics-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-other-procedures-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/other-procedures/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-other-procedures-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-pacifiers-and-thumb-sucking-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/pacifiers-and-thumb-sucking/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-pacifiers-and-thumb-sucking-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-panoramic-x-rays-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/panoramic-x-rays/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-panoramic-x-rays-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-pediatric-dental-appliances-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/pediatric-dental-appliances/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-pediatric-dental-appliances-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-pediatric-dentistry-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/pediatric-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-pediatric-dentistry-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-perinatal-and-infant-oral-health-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/perinatal-and-infant-oral-health/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-perinatal-and-infant-oral-health-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-periodontal-disease-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/periodontal-disease/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-periodontal-disease-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-procedures-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/procedures/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-procedures-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-repoositioning-teeth-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/repoositioning-teeth/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-repoositioning-teeth-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-restorations-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/restorations/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-restorations-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-root-canal-therapy-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/root-canal-therapy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-root-canal-therapy-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sealants-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/sealants/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sealants-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sealing-out-tooth-decay-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/sealing-out-tooth-decay/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sealing-out-tooth-decay-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sedation-dentistry-for-children-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/sedation-dentistry-for-children/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sedation-dentistry-for-children-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sedation-dentistry-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/sedation-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sedation-dentistry-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-signs-symptoms-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/signs-symptoms/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-signs-symptoms-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-simlpe-tooth-extractions-oral-maxillofacial-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/simlpe-tooth-extractions-oral-maxillofacial/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-simlpe-tooth-extractions-oral-maxillofacial-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-simple-tooth-extractions-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/simple-tooth-extractions/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-simple-tooth-extractions-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sippy-cups-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/sippy-cups/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-sippy-cups-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-special-needs-dentistry-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/special-needs-dentistry/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-special-needs-dentistry-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-suresmile-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/suresmile/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-suresmile-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-surgical-instructions-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/surgical-instructions/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-surgical-instructions-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-tabacco-use-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/tabacco-use/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-tabacco-use-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-tongue-and-lip-tie-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/tongue-and-lip-tie/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-tongue-and-lip-tie-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-tooth-extractions-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/tooth-extractions/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-tooth-extractions-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-treatment-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/treatment/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-treatment-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-types-of-braces-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/types-of-braces/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-types-of-braces-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-does-orthodontic-treatment-involve-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/what-does-orthodontic-treatment-involve/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-does-orthodontic-treatment-involve-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-is-an-orthodontist-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/what-is-an-orthodontist/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-is-an-orthodontist-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-is-malocclusion-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/what-is-malocclusion/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-is-malocclusion-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-is-orthodontics-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/what-is-orthodontics/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-what-is-orthodontics-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-whats-best-toothpaste-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/whats-best-toothpaste/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-whats-best-toothpaste-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-when-should-children-have-their-first-visit-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/when-should-children-have-their-first-visit/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-when-should-children-have-their-first-visit-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-when-should-my-child-get-an-orthodontic-evaluation-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/when-should-my-child-get-an-orthodontic-evaluation/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-when-should-my-child-get-an-orthodontic-evaluation-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-when-will-my-baby-start-to-get-teeth-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/when-will-my-baby-start-to-get-teeth/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-when-will-my-baby-start-to-get-teeth-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-who-can-benefit-from-orthodontics-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/who-can-benefit-from-orthodontics/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-who-can-benefit-from-orthodontics-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-why-are-primary-teeth-important-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/why-are-primary-teeth-important/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-why-are-primary-teeth-important-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-why-see-pediatric-dentist-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/why-see-pediatric-dentist/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-why-see-pediatric-dentist-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-why-straighten-teeth-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/why-straighten-teeth/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-why-straighten-teeth-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-content-procedures-xylitol-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/content/procedures/xylitol/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-content-procedures-xylitol-index-mdx" */),
  "component---src-templates-standard-template-js-content-file-path-src-images-what-is-pulp-therapy-index-mdx": () => import("./../../../src/templates/standard-template.js?__contentFilePath=/home/runner/work/kidz-dental-care/kidz-dental-care/src/images/what-is-pulp-therapy/index.mdx" /* webpackChunkName: "component---src-templates-standard-template-js-content-file-path-src-images-what-is-pulp-therapy-index-mdx" */)
}

