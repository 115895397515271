export const navMenu = [
    {
        name: 'For Parents',
        path: '/for-parents/',
        menu: [
            {
                name: 'Appointment',
                path: '/for-parents/appointments/',
            },
            {
                name: 'Meet The Doctors',
                path: '/for-parents/meet-the-doctors/',
            },
            {
                name: 'Meet Our Staff',
                path: '/for-parents/meet-our-staff/',
            },
            {
                name: 'Office Tour',
                path: '/for-parents/office-tour/',
            },
            {
                name: 'Our Community',
                path: '/for-parents/our-community/',
            },
            {
                name: 'Patient Reviews',
                path: '/for-parents/patient-reviews/',
            },
        ],
    },
    {
        name: 'Procedures',
        path: '/procedures/',
        menu: [
            {
                name: 'Cleanings & Prevention',
                path: '/procedures/cleanings-prevention/',
            },
            {
                name: 'Laser Teeth Whitening',
                path: '/procedures/other-procedures/laser-teeth-whitening/',
            },
            {
                name: 'Orthodontics',
                path: '/procedures/orthodontics/',
            },
            {
                name: 'Periodontal Disease',
                path: '/procedures/periodontal-disease/',
            },
            {
                name: 'Sedation Dentistry',
                path: '/procedures/other-procedures/sedation-dentistry/',
            },
            {
                name: 'Surgical Instructions',
                path: '/procedures/surgical-instructions/',
            },
            {
                name: 'Dental Anxiety and Fear',
                path: '/procedures/other-procedures/dental-anxiety-and-fear/',
            },
            {
                name: 'Oral & Maxillofacial Surgery',
                path: '/procedures/oral-maxillofacial-surgery/',
            },
            {
                name: 'Pediatric Dentistry',
                path: '/procedures/pediatric-dentistry/',
            },
            {
                name: 'Restorations',
                path: '/procedures/restorations/',
            },
            {
                name: 'Special Needs Dentistry',
                path: '/procedures/other-procedures/special-needs-dentistry/',
            },
            {
                name: 'Tongue and Lip Tie Laser Therapy',
                path: '/procedures/other-procedures/tongue-and-lip-tie-laser-therapy/',
            },
        ],
    },
    {
        name: 'Patient Resources',
        path: '/patient-resources/',
        menu: [
            {
                name: 'Dental Videos',
                path: '/patient-resources/dental-videos/',
            },
            {
                name: 'Financial Policy',
                path: '/patient-resources/financial-policy/',
            },
            {
                name: 'HIPPA Notice Of Privacy Practices',
                path: '/patient-resources/hipaa-notice-of-privacy-practices/',
            },
            {
                name: 'Office News',
                path: '/office-news/',
            },
            {
                name: 'Patient Information Form',
                path: '/patient-resources/patient-information-form/',
            },
            {
                name: 'Payment Options & Arrangements Form',
                path: '/patient-resources/payment-options-and-arrangements-form/',
            },
            {
                name: 'Procedure Financing',
                path: '/patient-resources/procedure-financing/',
            },
        ],
    },
];
