import { css, Global, Theme } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

type Size = {
    mobileS: string;
    mobileM: string;
    mobileL: string;
    tablet: string;
    laptop: string;
    laptopL: string;
    desktop: string;
    desktopL: string;
};

const size: Size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '630px',
    laptop: '1030px',
    laptopL: '1280px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const theme: Theme = {
    colors: {
        gray1: '#FCFCFC',
        gray2: '#D2DEE2',
        gray3: '#929EA4',
        gray5: '#3D3C3A',
        blue1: '#D3F0FC',
        blue2: '#98DBF8',
        blue3: '#0096D7',
        blue4: '#0B6B95',
        yellow1: '#FFF0C8',
        yellow3: '#E5A80B',
    },
    device: {
        mobileS: `(min-width: ${size.mobileS})`,
        mobileM: `(min-width: ${size.mobileM})`,
        mobileL: `(min-width: ${size.mobileL})`,
        tablet: `(min-width: ${size.tablet})`,
        laptopS: `(min-width: ${size.tablet}) and (orientation:landscape)`,
        laptop: `(min-width: ${size.laptop}) and (orientation:landscape)`,
        laptopL: `(min-width: ${size.laptopL})`,
        desktop: `(min-width: ${size.desktop})`,
        desktopL: `(min-width: ${size.desktopL})`,
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};
                html,
                body {
                    scroll-behavior: smooth;
                }
                body {
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    font-family: 'Poppins';
                    -webkit-font-smoothing: antialiased;
                }
                a {
                    text-decoration: none;
                }
                .container,
                .large-container {
                    margin: 0px 16px;
                }
                ul {
                    padding: 0;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                ul,
                li,
                button,
                div {
                    margin: 0;
                }
                .link {
                    font-weight: 700;
                    text-decoration: none;
                    color: ${theme.colors.blue3};
                }

                button {
                    border: none;
                    outline: none;
                    background: none;
                }
                .header {
                    font-weight: 700;
                    font-size: 28px;
                    line-height: 48px;
                    color: ${theme.colors.gray5};
                }
                .header-2 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 45px;
                    color: ${theme.colors.gray5};
                }

                .header-3 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 45px;
                    color: ${theme.colors.gray5};
                }
                p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: ${theme.colors.gray5};
                }
                @media ${theme.device.mobileL} {
                    .container,
                    .large-container {
                        margin: 0px 24px;
                    }
                }
                @media ${theme.device.tablet} {
                    .container,
                    .large-container {
                        max-width: 720px;
                        margin: 0 auto;
                        padding: 0 24px;
                    }
                    .header {
                        font-weight: 700;
                        font-size: 42px;
                        line-height: 48px;
                        color: ${theme.colors.gray5};
                    }
                    .header-2 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 45px;
                        color: ${theme.colors.gray5};
                    }
                    .header-3 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 28px;
                        line-height: 45px;
                        color: ${theme.colors.gray5};
                    }
                }
                @media ${theme.device.laptop} {
                    .container {
                        max-width: 1020px;
                    }
                    .large-container {
                        max-width: 1080px;
                    }
                    .header {
                        font-weight: 700;
                        font-size: 60px;
                        line-height: 72px;
                    }
                    .header-2 {
                        font-weight: 700;
                        font-size: 40px;
                        line-height: 64px;
                    }
                    .header-3 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 28px;
                        line-height: 40px;
                    }
                }
                @media ${theme.device.laptopL} {
                    .container {
                        max-width: 1064px;
                        margin: 0 auto;
                    }
                    .large-container {
                        max-width: 1200px;
                    }
                }
                @media ${theme.device.desktopL} {
                    .container {
                        margin: 0 auto;
                    }
                }
            `}
        />
    );
};
