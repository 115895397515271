import { GetInTouch } from '@/layout/Get-In-Touch';
import { Nav } from '@/layout/Nav';
import { ContactUs } from '@/layout/Contact-Us';
import { Footer } from '@/layout/Footer';

export const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <main>
            <Nav />
            {children}
            <GetInTouch />
            <ContactUs />
            <Footer />
        </main>
    );
};
